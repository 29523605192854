export function notifications(o = {} as any) {
    return [
        { 
            title: "Download FastX Webextension",
            content: `<p>
                Improve your user experience with the FastX Webexension. <br />
                Features Include
                <ul>
                    <li>Integrated Copy and Paste for Firefox</li>
                </ul>
                <a href="${ o.base }">Download Webextension</a>
            </p>`,
            show:  () => {
                return true;
            }
    
        }
    ];
}

