import { Injectable } from '@angular/core';
import { Token } from "@Common/api/util";

@Injectable()
export class TokenService {
  constructor() { }
  get () {
    return Token ();
  }

}
