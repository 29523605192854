import { Api } from "../../util";

export let Ssh = myExports ('/v3/api/auth/ssh');

function myExports (base) {
  let api = new Api (base);
  return {
    api: api,
    ssh: (d) => { return api.post ('/', d); },
    login: (d) => { return api.post ('/login', d); },
    keyboardInteractive: (d) => { return api.post ('/keyboard-interactive', d); },
    password: (d) => { return api.post ('/password', d); },
    publicKey: (d) => { return api.post ('/public-key', d);},
    publicKeySigned: (d) => { return api.post ('/public-key-signed', d);}
  }
}
