import { Component, OnInit, ViewChild } from '@angular/core';
import { SubmitCancelComponent } from "@Common/events";

@Component({
  selector: 'fx-ssh-sign',
  templateUrl: './sign.component.html',
  styleUrls: ['./sign.component.css']
})
export class SignComponent extends SubmitCancelComponent implements OnInit {
  @ViewChild ('footer', {static: false}) footer;
  constructor() {
    super ();
   }

  ngOnInit() {
   
  }
  ngAfterViewInit () {
    this.footer.submitButton.disabled = true;
  }

}
