import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExternalUrlService } from "@Common/util/external-url.service";
import { CommonUrlService } from "@Common/util/common-url.service"
import { ConfigModule } from "@Common/config";

@NgModule({
  imports: [
    CommonModule,
    ConfigModule
  ],
  declarations: [],
  providers: [
    ExternalUrlService,
    CommonUrlService,
  ]
})
export class UtilModule { }
