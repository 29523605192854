import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { NgbModal, NgbModalConfig } from "@ng-bootstrap/ng-bootstrap";
import { notifications } from "./system.notifications";
import { HttpClient } from '@angular/common/http'
import { ExternalUrlService } from '@Common/util';
import { interval } from 'rxjs';
import { UserConfigService, ConfigService} from '@Common/config';
import { UpgradeService } from "../../upgrade/upgrade.service"
import { User } from "@Common/api/user";


@Component({
  selector: 'button-notify',
  templateUrl: './notify.component.html',
  styleUrls: ['./notify.component.css']
})
export class NotifyComponent implements OnInit {
  @Input () base = ".."
  ucs$;
  constructor(
    private http: HttpClient,
    private externalUrl: ExternalUrlService,
    private modalService: NgbModal,
    private ucs: UserConfigService,
    private cdr: ChangeDetectorRef,
    private us: UpgradeService,
    private cs: ConfigService,
    private modalConfig: NgbModalConfig
    ) { 
      this.modalConfig.animation = false;
    }
  notifications = [];
  unread = [];
  noti = {} as any;
  upToDate;
  hideNotifications;
  lastUpdated;
  menoti = {} as any
  me = {} as any
  us$;
  cs$;
  up = {} as any;
  upgradeText = 'Update Available';
  config = {} as any;
  ngOnInit() {
    this.ucs$ = this.ucs.subscribe(d => {
      this.me = d;
      if(this.me && this.me.notifications 
        && this.me.notifications.updated === this.lastUpdated) {
        this.upToDate = true;

      } else {
        this.upToDate = false;
      }
      this.cdr.detectChanges();

    });
    this.us.getUpgrades().then(d => {
      this.up = d;
      this.cdr.detectChanges();
    });
    this.cs$ = this.cs.subscribe(d => {
      this.config = d;
    });
    setInterval(() => {
      this.http.get([this.base,"notifications"].join ('/') + '?limit=5', { responseType: 'text' })
      .subscribe(xml => {
        let parser = new DOMParser();
        let xmlDoc = this.xmlToJson(parser.parseFromString(xml, 'application/xml')) as any;

        this.notifications = this.getEntries(xmlDoc);
        if(!this.notifications.length) {
          this.upToDate = true;
          this.hideNotifications = true;
          this.cdr.detectChanges();
          return;
        }
        this.hideNotifications = false;
        if(this.me && this.me.notifications && xmlDoc.feed 
          && this.me.notifications.updated == xmlDoc.feed.updated['#text']) {
          this.upToDate = true;
          
        } else {
          this.upToDate = false;
        }
        this.cdr.detectChanges();
      });
    }, 120000);

    this.http.get([this.base,"notifications"].join ('/') + '?limit=5', { responseType: 'text' })
      .subscribe(xml => {
        let parser = new DOMParser();
        let xmlDoc = this.xmlToJson(parser.parseFromString(xml, 'application/xml')) as any;
        this.notifications = this.getEntries(xmlDoc);
        if(!this.notifications.length) {
          return;
        }
        if(xmlDoc.feed)
          this.lastUpdated = xmlDoc.feed.updated['#text'];
        if(this.me && this.me.notifications && xmlDoc.feed 
          && this.me.notifications.updated === xmlDoc.feed.updated['#text']) {
          this.upToDate = true;
         
        } else {
          this.upToDate = false;
        }

        this.cdr.detectChanges();
      });
    
  }
  ngOnDestroy() {
    this.ucs$.unsubscribe();
  }
  getEntries(xmlDoc) {
    if(!xmlDoc || !xmlDoc.feed || !xmlDoc.feed.entry)
      return [];
    return Array.isArray(xmlDoc.feed.entry) ? xmlDoc.feed.entry : [xmlDoc.feed.entry];
  }
  xmlToJson(xml) {
	
    // Create the return object
    var obj = {};
  
    if (xml.nodeType == 1) { // element
      // do attributes
      if (xml.attributes.length > 0) {
      obj["@attributes"] = {};
        for (var j = 0; j < xml.attributes.length; j++) {
          var attribute = xml.attributes.item(j);
          obj["@attributes"][attribute.nodeName] = attribute.nodeValue;
        }
      }
    } else if (xml.nodeType == 3) { // text
      obj = xml.nodeValue;
    }
  
    // do children
    if (xml.hasChildNodes()) {
      for(var i = 0; i < xml.childNodes.length; i++) {
        var item = xml.childNodes.item(i);
        var nodeName = item.nodeName;
        if (typeof(obj[nodeName]) == "undefined") {
          obj[nodeName] = this.xmlToJson(item);
        } else {
          if (typeof(obj[nodeName].push) == "undefined") {
            var old = obj[nodeName];
            obj[nodeName] = [];
            obj[nodeName].push(old);
          }
          obj[nodeName].push(this.xmlToJson(item));
        }
      }
    }
    return obj;
  };
  goNotify(noti, content) {
    this.noti = noti;
    this.modalService.open(content, { size: 'lg'});
  
  }
  updateNotify() {
    if(
      ((this.me.notifications.updated === this.lastUpdated) || !this.notifications.length ) &&
      (this.me.notifications.version === this.up.latest)
    ) {
      return;
    }
    User.editNotifications({ 
      login: this.me.login.sub, 
      data: { 
        updated: this.lastUpdated,
        version:  this.up.latest
      }
    }).then(d => {
      this.me.notifications.updated = this.lastUpdated;
      this.me.notifications.version = this.up.latest;
      this.cdr.detectChanges();
    })
    .catch(e => e); 

  }
  showUpgrades() {
    return  this.up.latest && (this.config.build !== this.up.latest);
  }
  bellClasses() {
    return this.me && this.me.notifications 
          &&  (
            ((this.me.notifications.version !== this.up.latest))
          ||  (this.notifications.length && (this.me.notifications.updated !== this.lastUpdated)) 
          ) ? 'text-danger' : '';
  }
  goToNotifications() {
    this.externalUrl.newWindow([this.base,"notifications.html"].join ('/'));
  }
  systemNotifications() {
    return notifications({
      base: this.base
    }).filter(c => c.show());
  }
  mapUpdateContent(up) {
    return {
      title: { ['#text']: this.upgradeText },
      content: { ['#text']: up.versions
      .map(c => '<a href="' + c.releaseNotesUrl + '">' +c.version + '</a>').join('<br />') }
    }
  }

}
