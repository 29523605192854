import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { SubmitCancelComponent } from "@Common/events";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { KeyStoreService } from "../../key-store.service";

@Component({
  selector: 'fx-ssh-decrypt',
  templateUrl: './decrypt.component.html',
  styleUrls: ['./decrypt.component.css']
})
export class DecryptComponent extends SubmitCancelComponent implements OnInit {
  prompt = "Enter passphrase for private key:";
  @Input () pk;
  @Input () set disabled (d) {
    if (!this.foot) return;
    this.foot.submitButton.disabled = d;
  }
  @Output () error = new EventEmitter ();
  @ViewChild ("pw", {static: false}) pw;
  @ViewChild ("foot", {static: false}) foot;

  _inputType = "password";
  inputType() {
    return this._inputType;
  }
  toggleInputType() {
    if(this._inputType === 'password')
      this._inputType = "text";
    else 
      this._inputType = 'password';
  }
  form: FormGroup;
  constructor(private fb: FormBuilder, private ks: KeyStoreService) { 
    super ()
  }
  name () {
    return this.pk.name;
  }
  ngOnInit() {
    this.form = this.fb.group ({
      passphrase: ['', Validators.required]
    });
  }
  ngAfterViewInit () {
    setTimeout (() => { this.pw.nativeElement.focus (); });
  }
  onSubmit () {
    let p = this.form.value;
    try {
      let pk = this.ks.decrypt (p.passphrase);
      this.foot.submitButton.disabled = true;
      if (pk) 
        return this.submit.emit (pk);
    }catch(e) {
      console.log(e);
    }
    
    this.foot.submitButton.disabled = false;
    this.error.emit (new Error ("Failed to decrypt private key"));
    this.form.reset ();
  }
  onCancel () {
    this.cancel.emit ();
  }
  
}
