import { Component, OnInit, Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { SubmitCancelComponent } from "@Common/events";

@Component({
  selector: 'fx-ssh-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css']
})
export class BannerComponent extends SubmitCancelComponent  implements OnInit {
  form: FormGroup;
  @Input () data:any = {};
  @ViewChild ("foot", {static: false}) foot;
  constructor(private fb: FormBuilder, private cdr: ChangeDetectorRef) {
    super ();
   }

  ngOnInit() {
    this.form = this.fb.group ({
      authtoken: ['', Validators.required],
      answer: ['', Validators.required]
    });
    this.form.patchValue ({ authtoken: this.data.authtoken });
  }
  ngAfterViewInit () {
    this.foot.submitButton.disabled = false;
    setTimeout(() => {
      this.foot.submitButton.button.nativeElement.focus();
      this.cdr.detectChanges();
    }, 100);
   
  }
  banner () {
    return this.data.banner;
  }
  onSubmit () {
    this.foot.submitButton.disabled = true;
    this.submit.emit (this.form.value);
  }
  onCancel () {
    this.foot.submitButton.disabled = true;
    this.cancel.emit ();
  }

}
