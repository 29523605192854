import { Injectable } from '@angular/core';
import KeyStore  from "../key-store";
import  PrivateKey  from "../private-key";
import  { KEYUTIL, utf8tob64 } from "jsrsasign";

@Injectable({
  providedIn: 'root'
})
export class KeyStoreService {
  private ks;
  private keys = [];
  private i = 0;
  constructor() { 
    this.ks = new KeyStore ();
  }
  usePK () {
    return this.keys.length > 0;
  }
  reset () {
    this.keys = this.ks.read ();
    this.i = 0;

  }
  setPk (pk) {
    let k = this.current ();
    if (!k) return;
    k.privateKey = pk;
    this.keys [this.i] = k;
  }
  getPk () {
    let c = this.current ();
    return c ? c.privateKey : null;
  }
  decrypt (passphrase = '') {
    let c = this.current ();
    let pk = PrivateKey.factory(c.key);
    if(pk.isEncrypted()) {
      return pk.decrypt(passphrase) ? pk : null;
    }
    return pk.decrypt() ? pk : null;
  }
  encrypted () {
    let c = this.current ();
    if(!c)
      return;
    let pk = PrivateKey.factory(c.key);
    return pk.isEncrypted();
  }
  current () {
    return this.keys.length <= this.i ? null : this.keys [this.i];
  }
  next () {
    return this.keys.length <= this.i ? null : this.keys [++this.i];
  }

}
