import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { DeleteButtonComponent } from "./delete-button/delete-button.component";
import { GridOptions } from "ag-grid-community";

const BASE = `{ "key": "", "value": "", "type": "string"}`;
@Component({
  selector: 'app-keyvalue',
  templateUrl: './keyvalue.component.html',
  styleUrls: ['./keyvalue.component.css']
})
export class KeyValueComponent implements OnInit {
  constructor(private cdr: ChangeDetectorRef) { }

  @Output () onUpdate = new EventEmitter ();
  @Output() startEditing = new EventEmitter();
  @Output() stopEditing = new EventEmitter();
  @Input () gridClass = '';
  @Input () service;
  @Input () set json (j) {
    this._json = j;
    if (!this.gridOptions.columnApi) return;
    this.gridOptions.columnApi.setColumnVisible('type',this._json);
    this.gridOptions.api.sizeColumnsToFit ();
    this.cdr.detectChanges ();
  };

  @Input () viewOnly = false;
  _json;
  _rows;
  gridOptions:GridOptions = {
    rowSelection: 'single',
    defaultColDef: {
      editable: true,
      resizable: true,
      singleClickEdit: true,
    },
    onCellEditingStarted: (event) => {
      this.startEditing.emit();
    },
    onCellEditingStopped: (event) => {
      this.stopEditing.emit();
      this.onUpdate.emit (this.toJson ());
     
    },
    getRowStyle: (params) => {
      return params.data.value === null ? {display: 'none'} : {}
    },
    columnDefs: [
      { headerName: 'Key', field: 'key' },
      { 
        headerName: 'Value', 
        field: 'value',
        cellEditorSelector: (params) => {
          switch (params.data.type) {
            case 'number':
              return { component: 'agTextCellEditor' } ;
            case 'boolean':
              return { component: 'agSelectCellEditor', params: { values: [true, false] }};
            case 'string':
            default: 
              return { component: 'agTextCellEditor' };
          }
        },
      } as any,
      { headerName: 'Type', field: 'type', 
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: ['string', 'number', 'boolean', 'null']
        }
       },
       {
         width: 50,
         editable: false,
         resizable: false,
         sortable: false,
         pinned: 'right',
         cellRendererFramework: DeleteButtonComponent, 
         cellRendererParams: { 
           del: () => {
            this.onUpdate.emit (this.toJson ());
           },
           show: () =>  !this.viewOnly
        }
       }
    ],
    onGridReady: () => {
      this.gridOptions.api.setRowData (this._rows);
      this.gridOptions.columnApi.setColumnVisible('type',this._json);
      this.gridOptions.api.sizeColumnsToFit ();
      this.cdr.detectChanges ();
    }
  }
 
  ngOnInit() {

  }
  ngAfterViewInit () {
    if (!this.service) return;
    this.service.subscribe (r => {
      this._rows = r;
      if (!this.gridOptions.api) return;
      this.gridOptions.api.setRowData (this._rows);
      this.cdr.detectChanges ();
    });
  }
  showAdd () {
    return !this.viewOnly;
  }
  add () { 
    if (!this._rows || !this._rows.length) this._rows = [JSON.parse(BASE)];
    else this._rows.push (JSON.parse(BASE));
    this.gridOptions.api.setRowData (this._rows);
    this.onUpdate.emit (this.toJson());
    this.cdr.detectChanges ();
  }
  enableAdd () {
    if (!this._rows  || !this._rows.length) return true;
    return this._rows [this._rows.length -1].key
  }
  toJson () {
    if (!this.gridOptions.api) return {};
    let items = {};
    this.gridOptions.api.forEachNode((node) => { 
        if (!node.data.key) return;
        switch (node.data.type) {
          case 'boolean':
            items [node.data.key] = (node.data.value === "true");
            break;
          case 'number':
            items [node.data.key] = parseFloat (node.data.value);
            break;
          case 'null':
            items [node.data.key] = null;
            break;
          default:
            items [node.data.key] = node.data.value;
            break;
        }
    });
    return items;
  }
  

}
