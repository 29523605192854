import { Component, OnInit, Input, HostListener, ChangeDetectorRef } from '@angular/core';
import { UserService } from '@Common/user';
import { ExternalUrlService } from '@Common/util/external-url.service';
import { LoggedOutService } from '@Common/page/ui/header/user/logged-out.service';
import { ModalService } from '@Common/modal';
import { ManageComponent } from "@Common/ssh/ssh"
import { HomePageComponent } from "./home-page/home-page.component";
import { ConfigService, UserConfigService } from '@Common/config';
import { AboutComponent } from '@Common/page/about/about.component';

@Component({
  selector: 'user-menu',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
  @Input () base = "./";
  @Input () page = '';
  cs$;
  ucs$;
  perms = <any>{
    user: {},
    utils: {},
  };
  user = <any>{};
  config = <any>{};
  utils = {};
  constructor(private us: UserService, 
    private cs: ConfigService,
    private cdr: ChangeDetectorRef,
    private externalUrl: ExternalUrlService, 
    private ls: LoggedOutService,
    private modal: ModalService,
    private ucs: UserConfigService,
  ) { }

  ngOnInit() {
    this.cs$ = this.cs.subscribe(d => {
      this.user = d.user;
      this.config = d;
      this.cdr.detectChanges();
    });
    this.ucs$ = this.ucs.subscribe(d => {
      let userPerms = (d.permissions.user || {});
      this.perms = { 
        user: userPerms.user,
        utils: userPerms.utils || {}
      };
    });
    //this.user = this.us.getUser ();
  }
  ngOnDestroy() {
    this.cs$.unsubscribe();
    this.ucs$.unsubscribe();
  }
  @HostListener ('document:snLoggedOut', ['$event']) logOutNotify (e) {
    this.ls.create (e.detail)
    .then (d => this.logout ())
    .catch (e => this.logout ())

  }
  logout () {
    try {
      window.sessionStorage.removeItem ('autoconnect');
    } catch(e) {
      console.log(e)
    }

    this.externalUrl.goToUrl ([this.base, 'user','logout'].join ('/'))
  }
  sshKey () {
    this.modal.create (ManageComponent)
    .then (d => d)
    .catch (e => e);
  }
  name () {
    return this.user.sub || 'Unknown User'
  }
  homePage () {
    this.modal.create (HomePageComponent)
    .then (d => d)
    .catch (e => e);
  }
  shortcutHref () {
    return [this.base, this.page === "session" ? "shortcut" : "session"].join ('/') + '/';
  }
  sessionOrShortcut () {
    return this.page === "session" ? 'Shortcut Mode' : (this.page === 'shortcut' ? 'Session Mode' : '');
  }
  goToProfile(section = '') {
    this.externalUrl.goToUrl ([this.base, 'user','profile' 
      + (';login=' + this.user.sub )
      + (section ? (';section=' + section) : '')].join ('/'))
  }
  goToFileManager() {
    this.externalUrl.newWindow([this.base, 'user','file-manager'].join ('/'))
  }
  goToTerminal() {
    this.externalUrl.newWindow([this.base, 'user','terminal'].join ('/'))
  }
  showAbout() {
    this.modal.create (AboutComponent, ref => ref, {
      size: 'lg'
    })
    .then (d => d)
    .catch (e => e);
  }
  


}
