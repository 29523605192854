import { Component, OnInit } from '@angular/core';
import { ConfigService } from "@Common/config";
import { CommonUrlService } from "@Common/util/common-url.service";

@Component({
  selector: 'fx-ssh-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(private url: CommonUrlService, private cs: ConfigService) { }
  build;
  config$;
  ngOnInit() {
    this.config$ = this.cs.subscribe (d => this.build = d.build);
  }
  ngOnDestroy () {
    this.config$.unsubscribe ();
  }
  poweredBy () {
    return this.url.poweredBy ();
  }
  release () {
    return this.url.release ();
  }
  

}
