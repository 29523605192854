import { Component, OnInit, Input, ChangeDetectorRef  } from '@angular/core';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalService } from '@Common/modal';
import { DataComponent } from "./data/data.component";

@Component({
  selector: 'error-alert',
  templateUrl: './error-alert.component.html',
  styleUrls: ['./error-alert.component.css']
})
export class ErrorAlertComponent implements OnInit {
  @Input () error;
  @Input () type = "danger";
  @Input () dismiss = true;
  _stack = false;
  constructor(private cdr: ChangeDetectorRef, private modal: ModalService) { }
  clear () {
    this.error = null;
  }
  ngOnInit() {
  }
  show () {
    return this.error && this.error.message;
  }
  errorType () {
    return this.type;
  }
  toggleStack () {
    this._stack = !this._stack;
  }
  showMore () {
    this.modal.create (DataComponent, ref => {
      ref.instance.error = this.error;
      ref.changeDetectorRef.detectChanges ();
    })
    .then (d => d )
    .catch (d => d)
  }
  hasMore () {
    return this.error && ( this.error.stack || this.error.data);
  }

}
