import { Component, OnInit, Input } from '@angular/core';
import { SubmitCancelComponent } from "@Common/events";
@Component({
  selector: 'app-data',
  templateUrl: './data.component.html',
  styleUrls: ['./data.component.css']
})
export class DataComponent extends SubmitCancelComponent implements OnInit {
  @Input () set error  (e) {
    this._error = Object.assign ({ stack: "", data: { log: {} }}, e);
  }
  info;
  protected _error = { stack: "", data: { log: {} }} as any;
  constructor() { super () }

  ngOnInit() {
    this.info = this._error.stack ? 'stack' : (Object.keys ((this._error.data || {}).log) || ['none']) [0]
  }
  onSubmit () {
    this.submit.emit ();
  }
  myData () {
    if (!this._error) return [];
    let d = [];
    if (this._error.stack) d.push ({ name: "stack", value:"stack" });
    if (!this._error.data) {
      if (d.length && !this.info) this.info = d [0].value;
      return d;
    }
    Object.keys (this._error.data.log || {})
    .forEach (c => {
      d.push ({
        name: c,
        value: c
      });
    });
    if (!this.info && d.length) this.info = d [0].value;
    return d;
  }
  getVal (v) {
    switch (v) {
      case "stack":
        return this._error.stack;
      default:
        return this._error.data.log [v];
    }
  }

}
