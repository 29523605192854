import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ViewContainerRef } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppComponent } from './app.component';
import { ConfigModule } from '@Common/config';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SshModule } from "@Common/ssh/ssh";
import { UtilModule } from "@Common/util";
import { PageModule } from "@Common/page"

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    
  ],
  imports: [
    BrowserModule,
    NgbModule,
    SshModule,
    ConfigModule,
    PageModule,
    UtilModule

  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
