import { Component, OnInit,  Output, EventEmitter, ViewChild } from '@angular/core';
import { FxEventEmitter } from "@Common/events";

@Component({
  selector: 'footer-submit-cancel',
  templateUrl: './submit-cancel.component.html',
  styleUrls: ['./submit-cancel.component.css']
})
export class SubmitCancelComponent implements OnInit {
  @Output () submit = new FxEventEmitter ();
  @Output () cancel = new FxEventEmitter ();
  @ViewChild ('submit', {static: false}) submitButton;
  @ViewChild ('cancel', {static: false}) cancelButton;
  constructor() { }

  ngOnInit() {
  }
  onSubmit (evt) { this.submit.emit (evt); }
  onCancel (evt) { this.cancel.emit (evt); }

}
