import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from "@angular/forms";

@Component({
  selector: 'form-tab-title',
  templateUrl: './form-tab-title.component.html',
  styleUrls: ['./form-tab-title.component.css']
})
export class FormTabTitleComponent implements OnInit {
  @Input () form: FormGroup;
  @Input () name: string;
  @Input () dirty: Function = () => {
    return this.form && this.form.dirty;
  }
  @Input () invalid: Function = () => {
    return this.form && this.form.invalid && !this.form.pristine
  }
  constructor() { }

  ngOnInit() {

  }
  title () {
    let t = this.name.charAt (0).toUpperCase () + this.name.slice (1);
    return [t, this.dirty () ? '*':''].join (' ');
  }
  titleClass () {
    if (this.invalid) return {}
    return { "" : this.invalid () }
  }

}
