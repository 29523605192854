import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';

@Component({
  selector: 'header-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.css']
})
export class ListItemComponent implements OnInit {
  @ViewChild ('item', { read: ViewContainerRef, static: false}) item: ViewContainerRef;
  constructor() { }

  ngOnInit() {
  }
  

}
