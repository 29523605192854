import { Component, OnInit, Input } from '@angular/core';
import { Action } from "@Common/page/action";


@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.css']
})
export class ActionsComponent implements OnInit {
  @Input () show: Boolean;
  @Input () actions:  Array<Action>;
  constructor() { 
  }

  ngOnInit() {
  
  }
  hide (fn: Function ){
    if(!fn)
      return false;
    return fn ();
  }
  makeId(action) {
    return 'action-' + action.name.toLowerCase().replace(/\W+/g,'-')
  }
}
