import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Bookmark } from '@Common/types/bookmark';
import { BookmarkService } from "@Common/session/actions/start/bookmark/bookmark.service";
import { NotifyService, Errors } from '@Common/notify';
import { CommandChangedService } from '../../start/command-changed.service';


const ICON = '../img/bookmark';

@Component({
  selector: 'fx-session-bookmark-element',
  templateUrl: './element.component.html',
  styleUrls: ['./element.component.scss']
})
export class ElementComponent implements OnInit {
  @Input() view = 'grid';
  @Input () data: Bookmark;
  @Input () selected: Boolean;
  @Output () started = new EventEmitter ();
  bs$;
  favs = [];
  user = [];
  hovering = false;
  highlight = false;
  constructor(private bs: BookmarkService, private ns: NotifyService, private cc: CommandChangedService) { }
  
  ngOnInit() {
    this.bs$ = this.bs.subscribe (d => {
      this.favs = d.favorites || [];
      this.user = (d.user || []).map (c => c.id) || [];
    });
  }
  name () {
    return this.data.name || this.data.command;
  }
  setClass () {
    return { selected: this.hovering, notSelected: !this.hovering, ccSelected: this.selected }
  }
  close () {
    
  }
  setSelect(b) {
    this.selected = b;
    if(this.selected)
      this.cc.id = this.data.id;
  }
  toggleSelect () {
    this.selected = !this.selected;
    if (this.selected) this.cc.id = this.data.id;
  }
  isSelected() {
    return this.selected;
  }
  icon () {
    return this.data.icon || ICON;
  }
  isFavorite () {
    return this.favs.indexOf (this.data.id) >= 0; 
  }
  favorite () {
    let p = this.isFavorite () ? this.bs.unfavorite (this.data.id) : this.bs.favorite (this.data.id);
    p
    .then (d => { this.bs.load () })
    .catch (e => this.ns.error (Errors.message (e)));
  }
  toggleHover (b) {
    this.hovering = b;
  }
  hover () {
    return this.hovering;
  }
  showUser () {
    return this.user.indexOf (this.data.id) >= 0;
  }
  remove () {
    this.bs.remove (this.data.id)
    .then (d => { this.bs.load () })
    .catch (e => this.ns.error (Errors.message (e)))
  }
  start () {
    this.started.emit (this.data);
  }
  setHovering(b) {
    this.hovering = b;
  }
  setHighlight(b) {
    this.highlight = b;
  }

}
