import { Injectable } from '@angular/core';
import { Bookmark } from "@Common/api/bookmark";
import { ReplaySubject } from "rxjs";
import { DataService, DataConfig } from '@Common/data';

export function bookmarkServiceFactory (config: BookmarkConfig) {
  return new BookmarkService (config);
}

@Injectable({
  providedIn: 'root'
})
export class BookmarkConfig extends DataConfig {
  load () {
    return Bookmark.list ()
  }
}

@Injectable({
  providedIn: 'root'
})
export class BookmarkService extends DataService {
  setRootPath (p) {
    Bookmark.api.setRootPath (p);
  }
  edit (b) {
    return Bookmark.edit (b);
  }
  remove (id) {
    return Bookmark.delete ({ id: id });
  }
  favorite (id) {
    return Bookmark.favorite({ id: id });
  }
  unfavorite (id) {
    return Bookmark.unfavorite({ id: id });
  }
  constructor (config: BookmarkConfig) {
    super (config);
  }
}
