import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ChangeDetectorRef} from '@angular/core';
import { BookmarkService } from '@Common/session/actions/start/bookmark/bookmark.service';
import { Bookmark } from "@Common/types/bookmark";
import { CommandChangedService } from '@Common/session/actions/start/start/command-changed.service';
import { UserConfigService } from '@Common/config';

@Component({
  selector: 'fx-session-bookmark',
  templateUrl: './bookmark.component.html',
  styleUrls: ['./bookmark.component.scss']
})
export class BookmarkComponent implements OnInit {
  sub$;
  textFilter = '';
  view = 'grid';
  @Output () selected = new EventEmitter ();
  @Output () started = new EventEmitter ();
  @Input () set rootPath (p) {
    this.bs.setRootPath (p);
  }
  private _selected = <Bookmark>{};
  constructor(private bs: BookmarkService, private cc: CommandChangedService, private cdr: ChangeDetectorRef, private ucs: UserConfigService) { }
  bookmarks = <any>{};
  tag: string;
  ucr$;

  ngOnInit() {
    this.sub$ = this.bs.subscribe (this.handle.bind (this));
    this.bs.setRootPath ('..')
    this.bs.load ();
    this.setSelected ({} as Bookmark);
    this.cc.id = null;
    this.ucr$ = this.ucs.subscribe((d:any) => {
      this.view = window.localStorage['bookmark-view'] ||  ((d.config && d.config.bookmark) || {} as any).defaultView || 'grid';
      
      this.tag = window.localStorage['bookmark-tag'] || ((d.config && d.config.bookmark) || {} as any).defaultTag ||  '';
      this.cdr.detectChanges();
    });
   
   
  }
  ngAfterViewInit () {
   
  }
  ngOnDestroy () {
    this.sub$.unsubscribe ();
    this.ucr$.unsubscribe();
  }
  setView(v) {
    this.view = v;
    window.localStorage['bookmark-view'] = this.view;
  }
  getView() {
    return this.view;
  }
  handle (d) {
    this.bookmarks = d;
    this.cdr.detectChanges ();
  }
  setSelected (b) {
    this._selected = b;
    this.selected.emit (b);
  }
  getBookmarks () {
    return this.bookmarks;
  }
  getSelected (b) {
    return b.id === this.cc.id;
  }
  onTagChanged (tag) {
    this.tag = tag;
    window.localStorage['bookmark-tag'] = this.tag;
  }
  filterByText (c) {
    if (!this.textFilter.length) return true;
    let l = this.textFilter.toLowerCase ();
    return ((c.command || '').toLowerCase().indexOf (l) >=0)
       || ((c.name || '').toLowerCase().indexOf (l) >= 0);
  }
  setTextFilter() {
    window.localStorage['bookmarkTextFilter'] = this.textFilter;
  }
  filterByTag (b, tag) {
    switch (tag) {
      case "favorites":
        return this.getFavorites ().filter (this.filterByText.bind (this));
      case "system":
        return (b.system || []).filter (this.filterByText.bind (this));;
      case "user": 
        return (b.user || []).filter (this.filterByText.bind (this));;
      case "history":
        return (b.history || []).filter (this.filterByText.bind (this));;
      case "all":
        return ((b.system || [])
          .concat (b.user || [])).filter (this.filterByText.bind (this));
      default: 
        return (this.getFiltered (tag, b.system)
          .concat (this.getFiltered (tag, b.user))).filter (this.filterByText.bind (this));;
    }
  }
  sortCaseInsensitive (arr = []) {
    return arr.sort ((a, b) => a.toLowerCase ().localCompare (b.toLowerCase ()));
  }
  tagLengths () {
    let b = this.getBookmarks ();
    return  {
      favorites: this.filterByTag (b, 'favorites').length,
      system: this.filterByTag (b, 'system').length,
      user: this.filterByTag (b, 'user').length,
      history: this.filterByTag (b, 'history').length
    }
  }
  start ($event) {
    this.started.emit ($event);
  }
  getBookmarkClasses() {
    return "float-left d-flex justify-content-center mr-1 mb-1";
  }
  private getFavorites () {
    let b = ([]
    .concat (this.bookmarks.system || [])
    .concat (this.bookmarks.user|| []))
    .filter (c => this.bookmarks.favorites && this.bookmarks.favorites.find (d => d === c.id));
    return b;
  }
  private getFiltered (tag, bookmarks) {
    if (!bookmarks) return  [];
    
    return bookmarks.filter (c => {
      if (!c.tags) return;
      return c.tags.indexOf (tag) >= 0;
    });
  }
}
