import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";

@Injectable()
export class UploadService {
    constructor(private http: HttpClient) {  }
    createUpload (opts = <any>{
        multiple: true
    }) {
        return new Promise ((resolve, reject) => {
        let el = document.createElement ('input');
        for (let i in opts) {
            el [i] = opts [i];
        }
        
        el.type = 'file';
       
        el.onchange = (e: any) => { 
            resolve (<FileList>(e.path ? e.path [0].files : e.target.files));
        }
        el.click ();
        });
    }
    readFile (file, opts = <any>{
        readAs:"readAsText"
    }) {
        let reader = new FileReader ();
        return new Promise ((resolve, reject) => {
            reader.onloadend = function () {
                return resolve (reader.result);
            }
            reader [opts.readAs] (file)
        });
    }
    upload (files:FileList, url) {
        const formData: FormData = new FormData();
        Array.from (files).forEach(file => {
            // create a new multipart-form for every file
            formData.append('files', file, file.name);
        });
        return this.http.post (url, formData);
    }
}