import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { EmitButtonComponent } from "@Common/button/emit-button/emit-button.component";
@Component({
  selector: 'cancel-button',
  templateUrl: './cancel-button.component.html',
  styleUrls: ['./cancel-button.component.css']
})
export class CancelButtonComponent extends EmitButtonComponent implements OnInit {
  constructor (private cdr: ChangeDetectorRef) {
    super ();
  }
  @Input () set (t) {
      this._text = t;
      this.cdr.detectChanges ();
  }
  _text = "Cancel";
  ngOnInit() {
  }
  getText () {
    return this._text;
  }

}
