import { Component, OnInit, Input } from '@angular/core';
import { ExternalUrlService } from '@Common/util/external-url.service';

@Component({
  selector: 'button-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  @Input () base = ".."
  constructor(private externalUrl: ExternalUrlService) { }

  ngOnInit() {
  }
  admin () {
    return [this.base,"system"].join ('/') + '/';
  }

}
