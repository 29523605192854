import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { ExternalUrlService } from '@Common/util/external-url.service';
import { UserConfigService } from '@Common/config';

@Component({
  selector: 'button-manage',
  templateUrl: './manage.component.html',
  styleUrls: ['./manage.component.css']
})
export class ManageComponent implements OnInit {
  @Input () base = ".."
  @Input() manager = {} as any;
  ucs$;
  perms;
  constructor(private externalUrl: ExternalUrlService, 
    private ucs: UserConfigService,
    private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.ucs$ = this.ucs.subscribe(d => {
      this.manager = (d.permissions || {}).manager || {};
      this.cdr.detectChanges();
    });
  }
  ngOnDestroy() {
    this.ucs$.unsubscribe();
  }
  admin (text) {
    this.externalUrl.goToUrl([this.base,"manage"].join ('/') + '/' + (text  || ''));
  }
  showMenu() {
    if(!this.manager)
      return;
    return Object.values(this.manager).some(c => c);
  }
  showUser() {
    
    if(!this.manager)
      return;
    return this.manager.user;
  }
  showSession() {
    return true;
    if(!this.manager)
      return;
    if(!this.manager.session)
      return;
    return Object.values(this.manager.session).some(c => c);
  }
  showServer() {
    if(!this.manager)
      return;
    return this.manager.server;
     
  }

}
