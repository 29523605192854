import { Component, OnInit, Input } from '@angular/core';
import { getHtml } from "@Common/page/ui/util";
import { ConfigService } from '@Common/config';

@Component({
  selector: 'fx-common-ui-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  data$;
  build;
  user;
  serverId;
  poweredBy;
  constructor(private config: ConfigService) { }

  ngOnInit() {
    this.data$ = this.config.subscribe (d => {
      this.build = d.build;
      this.serverId = d.serverId;
      this.user = d.user;
      this.poweredBy = [d.base, 'img', 'powered-by'].join ('/')
    });
  }
  ngOnDestroy () {
    this.data$.unsubscribe ();
  }

}
