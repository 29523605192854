import BU from "@Common/util/buffer-util";
import { b64tob64u, b64utohex, b64utob64, b64toutf8, hextob64u, KJUR, RSAKey,  KEYUTIL,  utf8tob64  } from "jsrsasign"; 
import { PrivateKey as PK } from "./interface";

class RSA implements PK {
    keyObj;
    str = '';
    constructor(str) {
        this.str = str;
    }
    isEncrypted() {
        let lines = this.str.split('\n');
        if(lines[0].search(/-----BEGIN RSA PRIVATE KEY-----/) >=0){
            return (lines[1].search(/ENCRYPTED/) >=0);
        }else if(lines[0].search(/-----BEGIN PRIVATE KEY-----/) >= 0){
            return false;
        }else if(lines[0].search(/-----BEGIN ENCRYPTED PRIVATE KEY-----/) >= 0){
            return true;
        }else {let k
            throw new Error('Invalid Key format. Not an RSA key');
        }
    }
    decrypt(passphrase = '') {
        this.keyObj = KEYUTIL.getKey (this.str, passphrase); 
        let teststr = 'myteststring'
        let t = this.sign (utf8tob64(teststr));
        return this.verify (teststr, t) ? this.keyObj : null;
    }
    sign(base64) {
        let hex = b64utohex(b64tob64u(base64));
        let sig = new KJUR.crypto.Signature({"alg": "SHA1withRSA"});
        sig.init(this.keyObj);
        sig.updateHex(hex);
        let hSigVal = sig.sign();
        return b64utob64(hextob64u(hSigVal));
    }
    verify(msg, b64Sig) {
        return this.keyObj.verify (msg, b64utohex(b64tob64u(b64Sig)))
    }
    getPublicKeyBlob() {
        let ba = this.keyObj.n.toByteArray();
        if(ba[0] === 0){
        //	ba.splice(0,1);
        }
        let str = 'ssh-rsa';
        let ab = new ArrayBuffer(
            4 //4 bytes - unsigned int: length X of string to come
            + str.length //X bytes - string: this will be 'ssh-rsa' (7 chars)
            + 4 //4 bytes - unsigned int: length Y of byte array
            + 4 //Y bytes - bigint of 'e'
            + 4 //4 bytes - unsigned int: length Z of byte array
            + ba.length  //Z bytes - bigint of 'n'
        );
        let dv = new DataView(ab);
        dv.setUint32(0,str.length);
        for(let i = 0; i< str.length; i++){
            dv.setUint8(4+i,str.charCodeAt(i));
        }
        dv.setUint32(4+str.length,4);
        dv.setUint32(8+str.length, this.keyObj.e);
        dv.setUint32(12+str.length,ba.length);
        let n = new Uint8Array(ab,16 +str.length);
        n.set(new Uint8Array(ba,0,ba.length -1));
        return BU.arrayBufferToBase64(ab);
    }
}
export default RSA;