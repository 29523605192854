import { Injectable } from '@angular/core';
import { ReplaySubject } from "rxjs";

export function selectedServiceFactory (config: SelectedConfig) {
  return new SelectedService (config);
}

@Injectable ({
  providedIn: 'root'
})
export class SelectedConfig {

}


@Injectable({
  providedIn: 'root'
})
export class SelectedService {
  private subject$ = new ReplaySubject (1);
  private selected = [];
  private config: SelectedConfig;
  constructor(config: SelectedConfig) { 
    this.config = config;
  }
  get length () {
    return this.selected.length;
  }
  clear () {
    this.selected = [];
    this.subject$.next (this.selected);
  }
  set (arr = []) {
    this.selected = arr;
    this.subject$.next (this.selected);
  }
  has (d) {
    return this.selected.includes (d);
  }
  select (d:any) {
    this.selected = [d];
    this.subject$.next (this.selected);
  }
  add (d:any) {
    if (this.selected.includes (d)) return;
    this.selected.push (d);
    this.subject$.next (this.selected);
  }
  unselect (fn = ((c, i, a) => {})) {
    let f = this.selected.filter (fn);
    this.selected = this.selected.filter (c => !f.includes (c));
    this.subject$.next (this.selected);
  }
  subscribe (d = (value => {})) {
    return this.subject$.asObservable ().subscribe (d);
  }
}
