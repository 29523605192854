import { Component, OnInit, Input, ViewChild  } from '@angular/core';
import { SubmitCancelComponent } from "@Common/events";
import { FormDataService } from '../form-data.service';

@Component({
  selector: 'fx-server',
  templateUrl: './server.component.html',
  styleUrls: ['./server.component.css']
})
export class ServerComponent extends SubmitCancelComponent  implements OnInit  {
  @Input () exec;
  @ViewChild ("foot", {static: false}) foot;
  @Input () data:any = {};
  @Input() config:any = {};
  error = {} as any;
  constructor(private fds: FormDataService)  {
    super ();
  }
  ngOnInit(): void {
  }
  ngAfterViewInit() {
    if(!this.data)
      return;
    this.fds.form.patchValue({
      serverId: this.data.automatic ? '' : this.data.servers[0].id
    })
  }
  hideError() {
    this.error = {};
  }
  getForm() {
    return this.fds.form;
  }
  getError() {
    return (typeof this.error === 'object') ? this.error.message : '';
  }
  async onSubmit () {
    this.foot.submitButton.disabled = true;

    try {
      this.fds.patchValue({ selectServer: false });
      this.submit.emit(this.fds.form.value);
    } catch(e) {
      this.error = e;
    }
  }
  onCancel () {
    this.foot.submitButton.disabled = true;
    this.cancel.emit ();
  }

}
