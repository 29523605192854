import { Injectable } from '@angular/core';
import { ReplaySubject } from "rxjs";

export function dataArrayServiceFactory (config: DataArrayConfig) {
    return new DataArrayService (config);
}

@Injectable ({
    providedIn: 'root'
})
export  class DataArrayConfig {
    load (d) { return Promise.resolve ({}); }
    find (a, b) { return a.id === b.id }
}

@Injectable({
  providedIn: 'root'
})
export class DataArrayService {
  protected subject$ = new ReplaySubject (1);
  protected data = [];
  protected config;
  protected errorFn = (e) => { console.log(e) }
  constructor(config: DataArrayConfig) { 
      this.config = config;
  }
  load (o = {}) {
    this.config.load (o)
    .then (d => {
      //merge in all the new ones;
      this.data = d;
      this.subject$.next (this.data);
    })
    .catch (this.errorFn.bind(this));
  }
  setError(fn) {
    this.errorFn = fn;
  }
  getSubject () {
    return this.subject$;
  }
  update (d) {
    if (!d.id) return;
    if (!this.data) this.data = [];
    let i = this.data.findIndex (s => this.config.find (s, d));
    if (i < 0 ) this.data.push (d);
    else this.data [i] = d;
    this.subject$.next (this.data);
  }
  delete (id) {
    if (!this.data) this.data = [];
    let i = this.data.findIndex (s => this.config.find (s, { id: id }));
    if (i < 0 ) return;
    this.data.splice (i, 1);
    this.subject$.next (this.data);
  }
  subscribe (fn = ((d) => {})) {
    return this.subject$.asObservable ().subscribe (fn);
  }
  private merge (data, d) {
    
    
  }
}
