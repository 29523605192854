import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { UserConfigService } from '@Common/config';
import { BookmarkService } from '@Common/session/actions/start/bookmark/bookmark.service';

enum TAGS {
  SYSTEM = "system",
  USER = "user",
  HISTORY = "history",
  FAVORITES = 'favorites',
  ALL = "all"
};

@Component({
  selector: 'fx-session-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.css']
})
export class TagComponent implements OnInit {
  tags = [];
  filter = [];
  selected;
  sub$;
  ucr$;
  @Input () tagsHaveBookmarks = {};
  @Output () changed = new EventEmitter ();
  constructor(private bs: BookmarkService, private cdr: ChangeDetectorRef, private ucr: UserConfigService) { }

  ngOnInit() {
    this.sub$ = this.bs.subscribe (this.setTags.bind (this));
    this.ucr$ = this.ucr.subscribe(d => {
      if(!d.config)
        return;
      if(!d.config.bookmark) {
       return;
      }
      this.filter = d.config.bookmark.hideTags || [];
      
      this.tags = this.tags.filter(c => {
        return this.filter.indexOf(c) < 0;
      });
      if(!this.tags.length) {
        this.tags.push('all');
      }

    });
  }
  ngOnDestroy () {
    this.sub$.unsubscribe ();
    this.ucr$.unsubscribe();
  }
  getTags () {
    return this.tags;
  }
  select (t?) {
    if (t) {
      this.selected = t;
      this.changed.emit (t);
    }
    return this.selected;
  }
  name (n) {
    switch (n) {
      case TAGS.FAVORITES:
        return "Favorites";
      case TAGS.SYSTEM:
        return "System";
      case TAGS.USER:
        return "User";
      case TAGS.ALL:
        return "All Bookmarks";
      default:
        return n;
    }
  }
  private setTags (d) {
    let t = [];
    for (let i in d) {
      t = t.concat (d[i].map (c => {
        if (!c || !c.tags) return [];
        c.tags.unshift (i);
        return c.tags;
      }))
    }
    let defaultTags =  Object.values (TAGS);

    this.tags = []
    .concat ([TAGS.FAVORITES, TAGS.USER])
    .filter (c => this.tagsHaveBookmarks [c] )
    .concat ((Array.from (new Set (
      t.reduce ((a,b) => a.concat (b), [])
      .filter( c => c )
      .filter (c => c.length)
      .filter ((c:TAGS) =>  !defaultTags.includes (c))
    )).sort ((a: TAGS, b: TAGS) => a.toLowerCase ().localeCompare (b.toLowerCase ()))));

    if (this.tagsHaveBookmarks ['system']) {
      this.tags.push (TAGS.SYSTEM);
    }
    this.tags.push (TAGS.ALL);

    this.tags = this.tags.filter(c => {
      return this.filter.indexOf(c) < 0;
    });
    if(!this.tags.length) {
      this.tags.push('all');
    }
    
    this.select (this.selected && this.tags.indexOf(this.selected) ? this.selected : this.tags [0]);
    //this.cdr.detectChanges ();
  }
  
}
