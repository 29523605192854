import { Injectable } from '@angular/core';
import { ReplaySubject } from "rxjs"
@Injectable({
  providedIn: 'root'
})
export class KvService {
  private sub = new ReplaySubject (1);
  constructor() { }
  load (d: Array<any>) {
    this.sub.next (d);
  }
  subscribe (fn =(d) => {}) {
    return this.sub.asObservable().subscribe (fn);
  }
}
