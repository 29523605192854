import { Component, OnInit, Input } from '@angular/core';
import { ICellRendererAngularComp } from "ag-grid-angular";


@Component({
  selector: 'fx-ssh-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.css']
})
export class DeleteComponent implements OnInit, ICellRendererAngularComp {
  @Input () params: any ;
  constructor() { }
  agInit(params: any): void {
    this.params = params;
   
  }
  refresh(): boolean {
      return false;
  }
  ngOnInit() {

  }
  show () {
    return this.params.node.selected;
  }
  remove () {
    this.params.remove (this.params.rowIndex);
  }
  name () {
    return this.params.data.name;
  }

}
