import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { DataArrayConfig, DataArrayService, dataArrayServiceFactory } from '@Common/data/data-array.service';
import { SelectedService } from "@Common/data/selected.service";
import { DataService, DataConfig, dataServiceFactory } from "@Common/data/data.service";

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [],
  exports: [],
  providers: [
    DataArrayConfig,
    { provide: DataArrayService, useFactory: dataArrayServiceFactory, deps: [DataArrayConfig] },
    //DataConfig,
    //{ provide: DataService, useFactory: dataServiceFactory, deps: [DataConfig]},
    SelectedService
  ]
})
export class DataModule { }
