import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { SubmitCancelComponent } from "@Common/events";
import { GridOptions } from "ag-grid-community";
import { DeleteComponent } from "./delete/delete.component";
import  KeyStore  from "../../key-store";

const VALID_START_STRINGS = [
  '-----BEGIN RSA PRIVATE KEY-----',
  '-----BEGIN PRIVATE KEY-----',
  '-----BEGIN ENCRYPTED PRIVATE KEY-----'
];


@Component({
  selector: 'fx-ssh-manage',
  templateUrl: './manage.component.html',
  styleUrls: ['./manage.component.css']
})
export class ManageComponent extends SubmitCancelComponent implements OnInit {
  keyStore = new KeyStore ();
  error:any;
  @ViewChild ("close", {static: false}) closeButton;
  @Input() newPosition = false;
  @Input() hideSaveButton = false;
  constructor() { super (); }
  gridOptions : GridOptions = {
    rowSelection: 'single',
    columnDefs: [
      {
        headerName: 'Private Key',
        cellRendererFramework: DeleteComponent,
        cellRendererParams: {
          remove: (index) => {
            this.keyStore.deleteIndex (index);
            this.gridOptions.api.setRowData (this.keyStore.read ());
          }
        }
      }
    ],
    onGridReady: ()  => {
      this.gridOptions.api.setRowData (this.keyStore.read ());
      this.gridOptions.api.sizeColumnsToFit ();
    }
  }
  ngOnInit() {
  }
  ngAfterViewInit () {
    if(this.closeButton && this.closeButton.submitButton)
      this.closeButton.submitButton.text =  'Done';
  }
  float() {
    return this.newPosition ? 'float-left' : 'float-right';
  }
 
  open () {
    (new Promise ((resolve, reject) => {
      let el = document.createElement ('input');
      el.type = 'file'
      el.multiple = true;
      el.onchange = (e: any) => {
        resolve (e.path ? e.path [0].files : e.target.files);
      }
      el.click ();
    }))
    .then (d => {
      let reader = new FileReader();
      reader.onload = (evt: any) => {
        let i = 0;
        while(i < VALID_START_STRINGS.length) {
          if(evt.target.result.startsWith(VALID_START_STRINGS[i])) {
            this.keyStore.add (d [0].name ,evt.target.result);
            this.gridOptions.api.setRowData (this.keyStore.read ());
            return;
          }
          i++;
        }
        this.error = new Error("Invalid Key Format")
       
      }
      reader.readAsText (d [0])
    })
    .catch (e => { this.error = e });
  }
  onSubmit () {
    this.submit.emit ();
  }

}
