export interface CompletedPromise {
    resolved?: Boolean;
    rejected?: Boolean;
    data: any

}

export function filterResolved (array: Array<Promise<any>>): Promise<Array<any>>{
        return new Promise ((resolve, reject) => {
            completeArray (array)
            .then (arr => resolve (arr.filter ((c: CompletedPromise) => c.resolved).map (c => c.data)))
            .catch (reject);
        });
}
export function filterRejected (array: Array<Promise<any>>): Promise<Array<any>>{
    return new Promise ((resolve, reject) => {
        completeArray (array)
        .then (arr => resolve (arr.filter ((c: CompletedPromise) => c.rejected)
            .map (c => c.data)))
        .catch (reject);
    });
}

function filter () {

}

function complete (c): Promise<CompletedPromise> {
    return new Promise ((resolve, reject) => {
        c.then (d => resolve ({ 
            resolved: true,
            data: d
        } as CompletedPromise))
        .catch (e => {
            resolve ({
            rejected: true,
            data: e
            } as CompletedPromise)
        });
    });
}

function completeArray (arr: Array<Promise<any>>): Promise<Array<CompletedPromise>> {
    return Promise.all (arr.map (complete))
}