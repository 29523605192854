import { Injectable } from '@angular/core';
import  KeyStore  from "../key-store"
import { Subject, from, ReplaySubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  sub = new ReplaySubject (1);
  constructor() {
    this.sub.next ({
      showPassword: true,
      showPK: true,
      usePK:  (new KeyStore ()).read ().length,
      banner: true,
    })
   }
  next (data) {
    this.sub.next (data);
  }
  auth (username = '', password = '') {
    return {
      username: username,
      password: password,
      usePK: (new KeyStore ()).read ().length,
     

    }
  }
  config () {
    return this.sub.asObservable ();
  }
}
