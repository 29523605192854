import { Component, OnInit, Input, ViewChild, ChangeDetectorRef  } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { SubmitCancelComponent } from "@Common/events";

@Component({
  selector: 'fx-keyboard-interactive',
  templateUrl: './keyboard-interactive.component.html',
  styleUrls: ['./keyboard-interactive.component.css']
})
export class KeyboardInteractiveComponent extends SubmitCancelComponent implements OnInit {
  @Input () data:any = {};
  form: FormGroup;
  @ViewChild ("foot", {static: false}) foot;
  @ViewChild ("pw", {static: false}) pw;
  _inputType = "password";
  inputType() {
    return this._inputType;
  }
  toggleInputType() {
    if(this._inputType === 'password')
      this._inputType = "text";
    else 
      this._inputType = 'password';
  }
  constructor(private fb: FormBuilder, private cdr: ChangeDetectorRef) {
    super ();
   }
  update () {
    this.ngOnInit ();
    this.foot.submitButton.disabled = false;
  }
  ngOnInit() {
    
    this.form = this.fb.group ({
      authtoken: ['', Validators.required],
      answer: ['', Validators.required]
    });
    this.form.patchValue ({ authtoken: this.data.authtoken });
  }
  ngAfterViewInit () {
    this.foot.submitButton.disabled = false;
    setTimeout(() => {
      this.pw.nativeElement.focus();
      this.cdr.detectChanges();
    }, 100);
  }
  prompt () {
    return (this.data.prompt || "").replace(/\s+$/, "");
  }
  onSubmit () {
    this.foot.submitButton.disabled = true;
    this.submit.emit (this.form.value);
  }
  onCancel () {
    this.foot.submitButton.disabled = true;
    this.cancel.emit ();
  }

}
