
export class Transaction{
  create (oldData, newData) {
    let add = [];
    let update = [];
    let remove = [];
    let i;
    
    oldData.forEach (d => {
      i = newData.findIndex (c => {
        return c.id === d.id; 
      });
      if (i < 0) return remove.push (d); //remove
      update.push (d); //update existing
      newData.splice (i,1);
    });
    newData.forEach (c => add.push (c));//add
    return this.make (add, update, remove);
  }
  make (add = [], update = [], remove = []) {
    return {
      add: add,
      update: update,
      remove: remove
    }
  }
}
