import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AgGridModule } from "ag-grid-angular";
import { ActionsComponent } from '@Common/page/actions/actions.component';
import { TitleComponent } from '@Common/page/title/title.component';
import { ErrorAlertComponent } from '@Common/page/error-alert/error-alert.component';
import { HttpClientJsonpModule, HttpClientModule } from "@angular/common/http";
import { FormTabTitleComponent } from '@Common/page/form-tab-title/form-tab-title.component';

import { ToastyModule } from "ngx-toasty";
import { TokenService } from '@Common/page/token.service';
import { UiModule } from "@Common/page/ui";
import { DialogModule } from '@Common/dialog';
import { ModalModule } from '@Common/modal';
import { DataComponent } from '@Common/page/error-alert/data/data.component';
import { UploadService } from "./upload.service";
import { KeyValueComponent } from './keyvalue/keyvalue.component';
import { KvService } from "./keyvalue/kv.service";
import { DeleteButtonComponent } from './keyvalue/delete-button/delete-button.component';

import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faTasks, faCog, faChartBar, faBell, faExclamation, faFolder  } from '@fortawesome/free-solid-svg-icons';


import { AboutComponent } from './about/about.component';

export let providers = [

  TokenService,
  UploadService,
  KvService
]

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AgGridModule.withComponents ([DeleteButtonComponent]),
    ToastyModule,
    UiModule,
    NgbModule,
    FontAwesomeModule,
    DialogModule,
    ModalModule,
    HttpClientModule,
    HttpClientJsonpModule
  ],
  declarations: [
    ActionsComponent, TitleComponent, 
    ErrorAlertComponent, FormTabTitleComponent, DataComponent, KeyValueComponent, DeleteButtonComponent, AboutComponent],
  exports: [
    ActionsComponent,
    TitleComponent,
    ErrorAlertComponent,
    FormTabTitleComponent,
    KeyValueComponent
  ],
  entryComponents: [DataComponent, AboutComponent],
  providers: providers
})
export class PageModule {
  constructor (library: FaIconLibrary) {
    library.addIcons(faTasks);
    library.addIcons(faCog);
    library.addIcons(faChartBar);
    library.addIcons(faBell);
    library.addIcons(faExclamation);
    library.addIcons(faFolder);
  }
 }
