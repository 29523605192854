import { Component, OnInit, ViewChild, Input, ChangeDetectorRef } from '@angular/core';
import { SubmitCancelComponent } from "@Common/events";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { KeyStoreService } from "../key-store.service";
import { ConfigService } from "../config.service";
import { FormDataService } from '../form-data.service';

@Component({
  selector: 'fx-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent extends SubmitCancelComponent implements OnInit {
  form = new FormGroup({});
  sub$;
  config:any = {};
  usePK = false;
  manage = false;
  @Input () error;
  @Input () disable = false;
  @Input() set adminLogin(l) {
    this.form.patchValue({ admin: l });
  }
  _inputType = "password";
  inputType() {
    return this._inputType;
  }
  toggleInputType() {
    if(this._inputType === 'password')
      this._inputType = "text";
    else 
      this._inputType = 'password';
  }
  constructor(private fb: FormBuilder, 
    private ks: KeyStoreService, 
    private cs: ConfigService,
    private cdr: ChangeDetectorRef,
    private fds: FormDataService ) { 
    super ();
  }
  @ViewChild ("username", {static: false}) un;
  
  ngOnInit() {
    this.disable = false;
    this.fds.reset();
    this.form = this.fds.form;
    this.sub$ = this.cs.config ().subscribe ((d:any) => {
      this.config = d;
      this.form.patchValue ({
        usePK: d.usePK || this.ks.usePK(),
        admin: d.disable
      });
      
      this.cdr.detectChanges ();
    });
  }
  ngAfterViewInit () {
    setTimeout (() => { this.un.nativeElement.focus (); });
  }
  ngOnDestroy () {
    this.sub$.unsubscribe ();
  }
  onSubmit () {
    this.disable = true;
    this.fds.patchValue(this.form.value)
    this.submit.emit (this.fds.value);
  }
  sshDisable() {
    return this.config.disable;
  }
  showPassword () {
    if(this.form.value['admin'])
      return true;
    return (this.config.showPassword);
  }
  showPublicKey () {
    return !this.config.hideManagePrivateKeys;

    // if(this.form.value['admin'])
    //   return true;
    // return   this.config.showPK;
  }
  showServers () {
    return this.config.servers && this.config.servers.length;
  }
  admin (bool) {
    this.fds.reset();
    this.form.patchValue({ admin: bool });
  }
  localLogin () {
    return this.form.value['admin'];
  }
  showManage () {
    return this.manage;
  }
  manageKeys () {
    this.manage = true;
  }
  onManageDone () {
    this.ks.reset ();
    this.manage = false;
  }
  showTerms() {

  }
  hasAuthMethods() {
    
  }

}
