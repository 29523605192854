import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SubmitCancelComponent } from '@Common/events';
import { ConfigService } from '@Common/config';
import {  BookmarkService } from '@Common/session/actions/start/bookmark';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})
export class HomePageComponent extends SubmitCancelComponent implements OnInit {
  form: FormGroup;
  cs$;
  bs$;
  config = {};
  constructor(private fb: FormBuilder, 
    private cs: ConfigService,
    private bs: BookmarkService,
    private cdr: ChangeDetectorRef) {
    super ();
   }
  homeOpts = [
    { name: "Sessions", val: "session" },
    { name: "Shortcuts", val: "shortcut" },
  ]
  bookmarks = {} as any;
  ngOnInit() {
    this.form = this.fb.group ({
      home: [this.homeOpts [0].val, Validators.required],
      disableQuickConnect: [false],
      autoconnect: ['$$default']
    });
    this.cs$ = this.cs.subscribeLocal (this.handle.bind (this));
    this.bs$ = this.bs.subscribe (this.handleBookmarks.bind (this));
    this.cs.loadLocal ();
    this.bs.load ();
  }
  ngOnDestroy () {
    this.cs$.unsubscribe ();
    this.bs$.unsubscribe();
  }
  handle (d) {
    try {
        this.form.patchValue ( JSON.parse (d ["homePage"]) );
        this.cdr.detectChanges ()
    } catch (e) {
       console.log (e)
    }
  }
  handleBookmarks (d) {
    this.bookmarks = d;
  }
  onSubmit () {
    if (!this.form.valid) return;
    this.cs.saveLocal ("homePage", this.form.value);
    this.submit.emit (this.form.value);
  }
  onCancel () {
    this.cancel.emit ();
  }

}
