import { Component, OnInit, Input, ViewChild  } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { SubmitCancelComponent } from "@Common/events";


@Component({
  selector: 'fx-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.css']
})
export class PasswordComponent extends SubmitCancelComponent  implements OnInit {
  form: FormGroup;
  prompt = "Password:";
  error = {} as any;
  @ViewChild ("pw", {static: false}) pw;
  @ViewChild ("foot", {static: false}) foot;
  @Input () data:any = {};

  _inputType = "password";
  inputType() {
    return this._inputType;
  }
  toggleInputType() {
    if(this._inputType === 'password')
      this._inputType = "text";
    else 
      this._inputType = 'password';
  }
  constructor(private fb: FormBuilder) {
    super ();
   }
  update (string) {
    this.error = new Error(string);
    this.ngOnInit ();
    this.foot.submitButton.disabled = false;
  }
  hideError() {
    this.error = {};
  }
  getError() {
    return (typeof this.error === 'object') ? this.error.message : ''
  }
  ngOnInit() {
    this.form = this.fb.group ({
      authtoken: ['', Validators.required],
      password: ['', Validators.required]
    });
    this.form.patchValue ({ authtoken: this.data.authtoken });
  }
  ngAfterViewInit () {
    this.foot.submitButton.disabled = false;
    setTimeout (() => { this.pw.nativeElement.focus (); }, 100);
  }
  onSubmit () {
    this.foot.submitButton.disabled = true;
    this.submit.emit (this.form.value);
  }
  onCancel () {
    this.foot.submitButton.disabled = true;
    this.cancel.emit ();
  }

}
